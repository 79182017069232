$content-max-width: 1024px;
$media-break-point: 868px;
$small-devices-break-point: 650px;


$primary-year-color: #3c6460;
$secondary-year-color: #f5bb10;

/*generated from https://angel-rs.github.io/css-color-filter-generator/ */
$primary-year-color-filter: brightness(0) saturate(100%) invert(36%) sepia(15%) saturate(1039%) hue-rotate(125deg) brightness(88%) contrast(83%);
$secondary-year-color-filter: brightness(0) saturate(100%) invert(69%) sepia(95%) saturate(555%) hue-rotate(353deg) brightness(99%) contrast(94%);
$third-year-color-filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%) hue-rotate(79deg) brightness(103%) contrast(101%);

@import "edicions-anteriors";
@import "header";
@import "general";
@import "agenda";

@import "footer";
