footer {
	margin: 0;
	padding: 10px;
  display: block;
  background-color: $secondary-year-color;
}

/* Layout*/
footer .container {
  display: flex;
  justify-content: space-between;
}

footer .container > div {
  max-width: 300px;
}

footer img {
  filter: $primary-year-color-filter;
}
footer p {
  font-weight: bold;
  margin-top: 0%;
  color: $primary-year-color;
}

footer a {
	color: $primary-year-color;
  text-decoration: underline;
  
}

footer a:hover {
	color:white;
}
footer a img:hover {
  filter: $third-year-color-filter;
}
footer h2 {
  color: $primary-year-color;

  font-weight: bold;
  margin-bottom: 3%;;
}

footer h3 {
  color: $primary-year-color;

  margin-bottom: 1%;
}

footer address {
  max-width: 300px;
}

footer .follow-us {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 12%;
  max-height: 39px;
}

footer .follow-us img {
  width: 50px;
  
}

footer .footer-divider {
  background-image: url("/assets/img/footer-divider.png");
  height: 50px;
  margin-top: 2%;
  margin-bottom: 3%;
  filter: $primary-year-color-filter;
}

// Icones que apareixen a l'esquerra (a poder ser, 50 x 50!)
.iconesFooter {
	
	float:left;
	margin-right: 7px;
	margin-left: 7px;
}

.iconesFooter img {
	width: 50px;
}
@media all and (max-width:$media-break-point) {
  footer .container {
    margin: auto;
    display: flex;
    flex-direction: column;
  }
}

#map iframe {
  height: 200px;
}

.container-web {
  max-height: 23%;;
}

@media print {
  footer {
    display: none;
  }
}