header {
	background-color: $secondary-year-color;
	margin: 0;
	padding: 10px;
  display: block;
}

header .container {
  display: flex;
  justify-content: space-between;
}

header .title {
  max-width: 400px;
  font-family: DIN, "Archivo Narrow", Helvetica, Arial, sans-serif;
}

header .title h1 {
  font-size: 1.5em;
  margin-bottom: 20px;
}
header .subheading b {
  color:$primary-year-color;
  font-size: 1.1em;
}

header hr {
  background-color: $primary-year-color;
}
header .navigationbar {
  max-width: 400px;
  text-align: right;
  display:flex;
justify-content:flex-end;
align-items:center;
}

header h1 a {
	font-family: DIN, "Archivo Narrow", Helvetica, Arial, sans-serif;
	text-transform:uppercase;
	font-size:1.5em;
  text-decoration: none;
  background: transparent;
  color: $primary-year-color;
  line-height: 1.1;
}

header a:hover {
	color:white;
	text-decoration: none;
}

header ul {
  padding-left: 0px;
  list-style: none;
  margin-top: 10px;
  margin-bottom: 0px;
}

header ul li {
  margin-bottom: 10px;
}

header li a {
	color:$primary-year-color;
  font-size: 1em;
  font-weight: bold;
  text-decoration: none;
}

header li.active a {
  color: white;
}
header .menubutton{
  display: none;
}
@media only screen and (min-width: $small-devices-break-point) and (max-width: $media-break-point) {
  header .container {
    margin: auto;
    display: flow-root;
    flex-direction: column;
  }
	header .subheading {
		font-size: 1.1em;
	}

	header .navigationbar {
		margin-top: 0px;
    width: 36%;
    float: right;
	}

	header .navigationbar li a {
		font-size: 1em;
   
	}

  header .title h1 {
      font-size: 1em;
      margin-bottom: 30px;
  }
  header h1, h2, h3, h4 {
    font-size: 1.5em;
  } 

  header .title {
    max-width: 400px;
    float: left;
  }

  header ul li {
    margin-bottom: 0px;
  }
}
@media only screen and (max-width: $small-devices-break-point) {
  header .title {
    max-width: 80%;
    font-size: 16pt;
  }
  header .title h1 {
    font-size: inherit;
    margin-top: 0px;
    margin-bottom: 10px;
  }
  header .title .subheading b {
    font-size: 15pt;
  }
  header .navigationbar {
    display: none;
  }
  header .menubutton {
    display: block;
    color: $primary-year-color;
    text-decoration: none;
    font-size: 40pt;
    margin-top: 10%;
  }
  header ul li {
    margin-bottom: 1px;
  }

}
@media print {

  body {
    background-color: none;
  }
  header .title {
    max-width:700px;
  }
  header .title h1 {
    font-size: 1em;
    margin-bottom: 1px;;
  }
  header .subheading {
    font-size: 0.8em;
    margin-bottom: 2px;
  }
 
 header .navigationbar {
    display: none;
  }
}

