@font-face {
    font-family: 'DIN';
    src: url('fonts/DINEngschriftStd.eot');
    src: url('fonts/DINEngschriftStd.eot?#iefix') format('embedded-opentype'),
        url('fonts/DINEngschriftStd.woff2') format('woff2'),
        url('fonts/DINEngschriftStd.woff') format('woff'),
        url('fonts/DINEngschriftStd.ttf') format('truetype'),
        url('fonts/DINEngschriftStd.svg#DIN') format('svg');
    font-weight: bold;
    font-style: normal;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Archivo Narrow", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.5em;
  line-height: 26px;
  color: #ECECEA;
  background: #c7224f;
	background-color: $primary-year-color;

}

.container {
  max-width: $content-max-width;
  margin: auto;
  padding: 10px;
}

h1, h2, h3, h4, b, strong {
	color: $secondary-year-color;
	text-decoration:none;
	font-weight:bold;
  margin-top: 5%;
  line-height: normal;
}

a {
  text-decoration: underline;
  text-decoration-style: dotted;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: 4px;
  color: $secondary-year-color;
  font-weight: bold;;
}

.button {
  text-align: center;
  background-color: $secondary-year-color;
  border: none;
  color: $primary-year-color;
  padding: 20px 34px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  margin: 4px 2px;
  cursor: pointer;
  font-weight: bold;;
}
.button:hover {
  color: $secondary-year-color;
  background-color: $primary-year-color;
}
hr{
  color: white;
  background-color: white;
  border: none;
  height:1px;
  margin-bottom: 6%;
  margin-top: 5%;

}

.xarxes img {
  filter: $secondary-year-color-filter;
}

textarea {
  background-color: $secondary-year-color;
  font-family: "Archivo Narrow", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1em;
  border: none;
  border-radius: 15px;
  padding: 10px;
}

.talk-keywords {
  display: flex;
}
@media only screen and (max-width: $small-devices-break-point) {
  .container {
    padding-left: 5%;
    padding-right: 5%;
  }
  p, ul, li, address, table {
    font-size: 15pt;
    line-height: normal;
  }

  h1 {
    font-size: 20pt;
  }
  h2, .footer h2 {
    font-size: 18pt;
  }
  h3 {
    font-size: 17pt;
  }
  textarea {
    font-size: 13pt;
    line-height: normal;
  }
  .button {
    font-size: 13pt;
    padding: 10px 12px;
  }
}