.base  {
  max-width:55%;
  float:left;
  padding-right: 1%;;
}
.smallroom{
  max-width:38%;
  float:right;
  padding-top: 50%;
}
.frame {
  width:100%;
}
.frame:after {
  display:table;
  clear:both;
  content: "";
}
.agenda {
  max-width: 600px;
}
.agenda-item {
  display: flex;
  align-items: baseline;
}

.agenda-item h2 {
    display: inline;
    font-family: DIN, "Archivo Narrow", Helvetica, Arial, sans-serif;
    line-height: 1em;
}

.agenda-item .time {
  max-width: 300px;
  margin-top: 30px;
}

.agenda-item .talks .talk {
  padding: 20px;
  flex: 1;

}

.talks{
  width: 100%;
}


.talks hr {
    border-top: 0;
    border-bottom: 3px dashed $secondary-year-color;
    background: none;
    margin-bottom: 0px;
    margin-top: 30px;

}

.talk-side {
  max-width: 500px;
}

.divider {
  background-image: url("/assets/img/footer-divider.png");
  height: 85px;
  margin-top: 5%;
  margin-bottom: 3%;
  filter: $secondary-year-color-filter;
  width: 100%;

}
@media only screen and (min-width: $small-devices-break-point) and (max-width: $media-break-point) {


  .agenda-item .time {
    font-size: 30px;
    line-height: 0.7em;
  }

  .agenda-item .talk {
    flex-direction: column;
  }
  .talk-keywords {
    display: block;
  }
}

@media only screen and (max-width: $small-devices-break-point) {

 .agenda-item .time {
  writing-mode: sideways-lr;
  -webkit-writing-mode: vertical-lr;
  margin-bottom: -20px;
  margin-right: -10px;
  margin-top: 0px;
  padding-top: 20px;
 }
 .agenda-item .talks .talk {
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  text-wrap: break-word;
 }
 .agenda-item .talks .talk h2 {
  margin-top: 0px;
  
 }
 .agenda-item .talks .talk a {
  text-decoration-thickness: 2px;
 }
 .agenda-item .talks p {
  font-size: 12pt;;
 }
 .talk hr {
  margin-bottom: 0px;
 }
 .talk-description p {
  font-size: 11pt;
 }
  div .container ul {
    display: block;
  }
  .talk-keywords {
    display: block;
  }

}

@media print {
body {
    h1 {
    font-size: 0.9em;
    margin-top:0px;
    margin-bottom: 0px;
    }

    h2 {
    font-size: 0.6em;
    margin-top: 10px;
    line-height: normal;;
    }

    h3 {
      font-size: 0.5em;
    }
  
    p {
    font-size: 0.3em;
    margin-bottom: 10px;
    line-height: normal;
  }

 .agenda-item .time {
    display: inline;
    flex-direction: column;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 0.5em;
  }


}
.container {
  .agenda-item {
    display: inline-felx;
    line-height: 80%;
  }

  .agenda-item .talks hr {
    margin-top:10px;
    margin-bottom:0px
  }

  .agenda-item .time {
    margin-top: 10px;
  }
  .agenda-item .talks .talk {
    padding-bottom:3%;
    padding-top: 1px;
    padding-bottom: 5px;
  }
  .smallroom {
    padding-top: 20%;
  }
}
}


//Amaga la part del msc a l'imprimir
@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}